import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"
import ScrollBtn from "../components/scroll-btn"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ProjectPage = props => {
  const project = props.data.strapiProject
  const parent = props.data.strapiProjectsPage

  const seo = {
    metaTitle: project.Title,
    metaDescription: project.Text.data.Text,
    shareImage: project.IntroImage.url,
  }

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={props.data.strapiProject.locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiProject.localizations.data[0]?.attributes
        }
        parent={parent.localizations.data[0].attributes.slug}
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiProjectsPage.slug}
        isPersistant={true}
      />
      <Layout as="project">
        <Seo
          lang={props.data.strapiProject.locale}
          description={project.Text.data.Text}
          title={project.Title}
          seo={seo}
        />
        <section id={project.slug} className="project-hero">
          <style>
            {`
            #${project.slug} {
              --project-color: ${project.Color};
            }
            `}
          </style>
          <div className="project-hero__wrapper">
            <div className="project-hero__content" style={{ color: "#ffffff" }}>
              <p className="project-hero__name">{project.ProjectName}</p>
              <h1 className="project-hero__title">{project.Title}</h1>
              <p className="project-hero__text">{project.Text.data.Text}</p>
            </div>
            <div className="project-hero__image-wrapper">
              <img
                className="project-hero__image"
                src={project.IntroImage.url}
                alt={project.Title}
                loading="lazy"
              />
            </div>
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
              circleColor={"linear-gradient(180deg, #ffffff 52%, #ffffff 93%)"}
              textColor={"#ffffff"}
              arrowColor={project.Color}
            />
          </div>
        </section>
        <section className="project-intro">
          <div className="project-intro__what-we-did">
            <div className="hero-circles-container">
              <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
              <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
              <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
              <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
            </div>
            <h2 className="project-intro__what-we-did-title">
              {project.WhatWeDidTitle}
            </h2>
            {project.WhatWeDidList.map((item, index) => {
              return (
                <p key={index} className="project-intro__what-we-did-item">
                  {item.Text}
                </p>
              )
            })}
            {project.ViewWebsiteLink && (
              <Link
                to={project.ViewWebsiteLink}
                className="project-intro__what-we-did-link"
              >
                {project.ViewWebsiteText}
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 1L14 7L8 13" stroke="#181C2F" stroke-width="2" />
                  <path d="M13 7H0" stroke="#181C2F" stroke-width="2" />
                </svg>
              </Link>
            )}
          </div>
          <div className="project-intro__content">
            <h2 className="project-intro__title">{project.ClientIntroTitle}</h2>
            <div className="project-intro__text">
              <ReactMarkdown>
                {project.ClientIntroText.data.ClientIntroText}
              </ReactMarkdown>
            </div>
          </div>
        </section>
        {project.ImageSection && (
          <section className="project-image-section">
            <picture>
              <source
                media="(min-width:1024px)"
                srcSet={project.ImageSection.url}
              />
              <img
                src={
                  project.ImageSectionMobile.url
                    ? project.ImageSectionMobile.url
                    : project.ImageSection.url
                }
                alt={project.ImageSection.alternativeText}
                loading="lazy"
              />
            </picture>
          </section>
        )}
        <section className="project-content">
          <ReactMarkdown>{project.Content.data.Content}</ReactMarkdown>
        </section>
        {project.ImageSectionLower && (
          <section className="project-image-section">
            <picture>
              <source
                media="(min-width:1024px)"
                srcSet={project.ImageSectionLower.url}
              />
              <img
                src={
                  project.ImageSectionLowerMobile.url
                    ? project.ImageSectionLowerMobile.url
                    : project.ImageSectionLower.url
                }
                alt={project.ImageSectionLower.alternativeText}
                loading="lazy"
              />
            </picture>
          </section>
        )}
        <section className="project-outcome">
          <div className="project-outcome__content">
            <h2 className="project-outcome__title">{project.OutcomeTitle}</h2>
            <ReactMarkdown>
              {project.OutcomeText?.data.OutcomeText}
            </ReactMarkdown>
          </div>
          {project.TestimonialComponent && (
            <div className="project-outcome__testimonial">
              <div className="project-outcome__testimonial-text">
                <ReactMarkdown>
                  {project.TestimonialComponent?.Text?.data.Text}
                </ReactMarkdown>
              </div>
              <p className="project-outcome__testimonial-name">
                {project.TestimonialComponent?.Name}
              </p>
              <p className="project-outcome__testimonial-position">
                {project.TestimonialComponent?.Position}
              </p>
            </div>
          )}
        </section>
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        locale={props.data.strapiProject.locale}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiProjectsPage(locale: { eq: $locale }) {
      slug
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiProject(locale: { eq: $locale }, slug: { eq: $slug }) {
      slug
      locale
      ProjectName
      Title
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
      ClientIntroText {
        data {
          ClientIntroText
        }
      }
      ClientIntroTitle
      Color
      Content {
        data {
          Content
        }
      }
      ImageSection {
        url
        alternativeText
      }
      ImageSectionLower {
        url
        alternativeText
      }
      ImageSectionLowerMobile {
        url
        alternativeText
      }
      ImageSectionMobile {
        url
        alternativeText
      }
      IntroImage {
        url
      }
      OutcomeText {
        data {
          OutcomeText
        }
      }
      OutcomeTitle
      Text {
        data {
          Text
        }
      }
      ViewWebsiteLink
      ViewWebsiteText
      WhatWeDidList {
        Text
      }
      WhatWeDidTitle
      TestimonialComponent {
        Name
        Position
        Text {
          data {
            Text
          }
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default ProjectPage
